import Login from 'components/auth/Login';
import { NextSeo } from 'next-seo';

const LoginPage = () => (
  <>
    <NextSeo title="Login" description="Pickup where you left." canonical="/signin" />
    <Login />
  </>
);

export default LoginPage;
