import { gql, GraphQLClient } from 'graphql-request';
import toastr from 'toastr';

async function main(values) {
  let isLoggedIn;
  let token;
  let name;
  let id;

  toastr.options = {
    positionClass: 'toast-top-right',
    hideDuration: 300,
    timeOut: 10000,
    closeButton: true
  };
  toastr.clear();

  try {
    const graphQLClient = new GraphQLClient(process.env.NEXT_PUBLIC_API_ENDPOINT);

    const mutation = gql`
      mutation loginUser($email: String!, $password: String!) {
        loginUser(input: { email: $email, password: $password }) {
          token
          isLoggedIn
          name
          id
        }
      }
    `;
    const variables = {
      email: values.email,
      password: values.password
    };

    const { loginUser } = await graphQLClient.request(mutation, variables);
    if (loginUser) {
      toastr.success(`Logged in successfully. Redirecting...`);
      isLoggedIn = loginUser.isLoggedIn;
      token = loginUser.token;
      name = loginUser.name;
      id = loginUser.id;
    }

    return { isLoggedIn, token, name, id };
  } catch (error) {
    let errors;
    if (error && error.response && error.response.errors && Array.isArray(error.response.errors)) {
      errors = error.response.errors;
    }
    if (errors) {
      errors.map((err) => {
        console.log({ err });
        toastr.error(err.message.message || err.message);
      });
    }
  }
}

export default main;
