/* eslint-disable no-empty-pattern */
import { Form, Formik } from 'formik';
import Image from 'next/image';
import Link from 'next/link';
import Router from 'next/router';
import closeIcon from 'public/images/close-icon.svg';
import googleIcon from 'public/images/google.png';
import logoIcon from 'public/images/logo-icon.png';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import * as Yup from 'yup';

import { PrimarySubmitButton, TransparentRoundButton } from '@/design-system/buttons';

import main from '../../utils/signin';
import { MyCheckbox, MyTextInput } from '../shared/form-inputs';
import { StyledAuthLayout } from './Signup';

const Login = () => {
  const [, setCookie] = useCookies([]);
  const [disableButton, setDisableButton] = useState(false);
  return (
    <StyledAuthLayout>
      <div className="first-col"></div>
      <div className="second-col"></div>
      <div className="overlay">
        <div className="form-section">
          <div>
            <div className="form-title">Log in with</div>
            <div className="social">
              <Link href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/google`}>
                <a>
                  <Image src={googleIcon} alt="Google Icon" placeholder="blur" />
                </a>
              </Link>
              {/*<Link href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/twitter`}>
                <a>
                  <Image src={twitterIcon} alt="Twitter Icon" placeholder="blur" />
                </a>
              </Link>
              <Link href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/github`}>
                <a>
                  <Image src={githubIcon} alt="Github Icon" placeholder="blur" />
                </a>
              </Link> */}
            </div>
            <div className="dividers">
              <div className="line" />
              {/* <div className="divider-text">or</div> */}
              <div className="line" />
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <Formik
              initialValues={{
                email: '',
                password: '',
                remember: false, // added for our checkbox
                jobType: '' // added for our select
              }}
              validationSchema={Yup.object({
                email: Yup.string().email('Invalid email address').required('Required'),
                password: Yup.string().required('Required')
              })}
              onSubmit={async (values, {}) => {
                setDisableButton(true);
                try {
                  let date = new Date();
                  date.setTime(date.getTime() + 60 * 60 * 1000 * 1); // token expires in 1 hour
                  const { isLoggedIn, token, name, id } = await main(values);
                  setCookie('isLoggedIn', isLoggedIn, { path: '/' });
                  setCookie('token', token, {
                    path: '/',
                    expires: values.remember ? null : date
                  });
                  setCookie('email', values.email, { path: '/' });
                  global.analytics.track('User Signed In');
                  global.analytics.identify(id, {
                    name,
                    email: values.email
                  });
                  const params = Router.query.from || '/';
                  let previousPath = Router?.router?.query?.redirectFrom;
                  if (previousPath == 'paymentModal') {
                    location.assign(`${Router?.router?.query?.redirectTo}?redirect=true`);
                  } else if (previousPath == 'pricing') {
                    location.assign(`${Router?.router?.query?.redirectTo}?redirect=true`);
                  } else if (previousPath == 'team') {
                    location.assign(`${Router?.router?.query?.redirectTo}?redirect=true`);
                  } else {
                    location.assign(params);
                  }
                } catch (error) {
                  setDisableButton(false);
                }
              }}>
              {({ isSubmitting }) => (
                <Form>
                  <MyTextInput
                    label="Email"
                    name="email"
                    type="email"
                    id="email"
                    placeholder="you@email.com"
                  />
                  <MyTextInput
                    label="Password"
                    name="password"
                    id="password"
                    type="password"
                    placeholder="***********"
                  />
                  <MyCheckbox name="remember"> Remember me</MyCheckbox>

                  <div className="form-action">
                    <PrimarySubmitButton
                      medium
                      type="submit"
                      disabled={isSubmitting || disableButton}>
                      Log in
                    </PrimarySubmitButton>
                    <div className="terms">
                      <Link href="/forgot-password">
                        <a>Forgot Password?</a>
                      </Link>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="brand-section">
          <Link href="/">
            <a className="close-section">
              <Image src={closeIcon} alt="Close Icon" />
            </a>
          </Link>
          <div className="logo">
            <Link href="/">
              <a>
                <Image src={logoIcon} alt="Logo" placeholder="blur" />
                <span>BrytaHub</span>
              </a>
            </Link>
          </div>
          <div className="description">
            <div className="desc-title">Welcome Back</div>
            <div className="desc-subtitle">Login to stay connected with your account</div>
          </div>
          <div className="desc-action">
            <span>Don&apos;t have an account?</span>
            <Link href="/register" passHref>
              <TransparentRoundButton medium>Sign Up</TransparentRoundButton>
            </Link>
          </div>
        </div>
      </div>
    </StyledAuthLayout>
  );
};

export default Login;
